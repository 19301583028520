import { ref, onMounted, onUnmounted, computed, watch, Ref } from 'vue';
import {getArticleList, getArticleDetails} from '@/api/api';
import store from '@/store/index';

export function useVideoList (subject: Ref<string>, model: Ref<string>) {

    const videos = ref([]);
    const page = ref(1);

    const getVideoList = async () => {

        let cid = '';

        if (subject.value === 'k3') {
            cid = String(store.state.training.config.subject_k3_cid);
        }
        else if (subject.value === 'k2') {
            cid = String(store.state.training.config.subject_k2_cid);
        }
        else {
            videos.value = [];
        }

        if (cid) {
            const res = await getArticleList(cid, {
                page: page.value
            });

            videos.value = res.data;
        }
    };

    onMounted(getVideoList);
    watch([subject, page], getVideoList);

    return {
        videos,
        page
    };
}

export function useVideoDetail (id: Ref<number>) {

    const video = ref({});
    const hasAccess = ref(true);

    const getVideoDetail = async () => {

        const res = await getArticleDetails(id.value);
        const cid = String(res.data.cid);
        const trainingAccess = store.getters['training/trainingAccess'];

        if (String(store.state.training.config.subject_k3_cid) === cid && !trainingAccess('k3')) {
            hasAccess.value = false;
        }
        else if (String(store.state.training.config.subject_k2_cid) === cid && !trainingAccess('k2')) {
            hasAccess.value = false;
        }
        else {
            video.value = res.data;
        }
    };

    onMounted(getVideoDetail);
    watch([id], getVideoDetail);

    return {
        video,
        hasAccess
    };
}