<template>
    <div class="container news-page">

        <bread-crumbs />

        <div class="frame-l-r">
            <div class="left">

                <div class="header" v-if="subject === 'k3'">
                    <h1>小车科目三考试</h1>
                    <h2>小车科目三考试又叫大路考，您需要掌握包括上车准备、直线行驶、百米加减档、灯光模拟考试等17项科目三考试技巧，满分为100分，90分及以上为及格。更多内容请看小车C1科目三考试内容及合格标准。</h2>
                </div>

                <div class="header" v-else>
                    <h1>小车科目二考试</h1>
                    <h2>小车科目二考试又叫小路考，包括倒车入库、侧方停车、坡道定点、直角拐弯、曲线行驶等5个科目二考试技巧项目，满分100分，80分及以上合格，更多内容请看小车C1科目二考试内容及合格标准。</h2>
                </div>

                <div class="com-video-static-part com-part">
                    <h3 class="part-title">{{subject === 'k3' ? '科目三' : '科目二'}}视频讲解</h3>
                    <ul class="clearfix">

                        <li v-for="item in videos" :key="item.id">
                            <router-link :to="{path: '/videos/detail', query: {id: item.id}}">
                                <div class="img-w">
                                    <img :src="item.image_input[0]" :alt="item.title" />
                                </div>
                                <p class="title">{{item.title}}</p>
                            </router-link>
                        </li>

                    </ul>
                </div>
            </div>

            <!-- <div class="right">
                <a class="ksyy-entry">
                    <div class="ksyy-left"></div>
                    <div class="ksyy-right">
                        <p class="ksyy-right-title">考试预约</p>
                        <p class="ksyy-right-detail">在线快捷考试预约入口</p>
                    </div>
                </a>
            </div> -->
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, Ref } from 'vue';

import {useVideoList} from '@/compositions/videos';

import BreadCrumbs from '@/components/widgets/bread-crumbs.vue';

export default defineComponent({
    name: 'videos-index',

    components: {
        BreadCrumbs
    },

    props: {
        subject: String,
        model: String
    },

    setup: function (props) {

        const {subject, model} = toRefs(props);

        return useVideoList(subject as Ref<string>, model as Ref<string>);
    }
});
</script>
