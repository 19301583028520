
import { defineComponent, toRefs, Ref } from 'vue';

import {useVideoList} from '@/compositions/videos';

import BreadCrumbs from '@/components/widgets/bread-crumbs.vue';

export default defineComponent({
    name: 'videos-index',

    components: {
        BreadCrumbs
    },

    props: {
        subject: String,
        model: String
    },

    setup: function (props) {

        const {subject, model} = toRefs(props);

        return useVideoList(subject as Ref<string>, model as Ref<string>);
    }
});
